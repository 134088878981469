import axios from 'axios';
import getHeaderToken from 'functions/getHeaderToken';
import{ Logger } from 'aws-amplify';
const logger = new Logger('putOptionPermissionSwitch-log');

const putOptionPermissionSwitch = (perms, app_id, function_id, state, setState) => {

    // Input validation
    let alertMsg = "エラーにより実行できません";
    const arr = perms.data;
    if ( !arr || arr.length === 0 ) { alert(alertMsg); return; }
    const office_no = arr[0].office_number;
    if ( !office_no || office_no.length === 0 ) { alert(alertMsg); return; }
    if ( !app_id || typeof app_id != 'string' ) { alert(alertMsg); return; }
    if ( !function_id || typeof function_id != 'string' ) { alert(alertMsg); return; }

    // Check current permission and return statusToChange
    if ( state === 0 || state === 1 || state === null ) {

        let statusToChange = 1;
        if ( state === 1 ) { statusToChange = 0; }


        // Define POST or PUT function depending on the request type
        const sendRequest = async ( requestType ) => {

            let rtn = {
                'status': 0,
                'isAlert': false,
                'msg': '',
            };

            const url = process.env.REACT_APP_OFFICE_PERMISSION;
            const ep = url + `/${office_no}`;

            let body;
            if ( requestType === 'put' ) { 
                body = {
                    office_no: office_no,
                    app_id: app_id,
                    func_id: function_id,
                    statusToChange: statusToChange,
                };
            } else if ( requestType === 'post' ) {
                body = {
                    office_no: office_no,
                    app_id: app_id,
                    func_id: function_id,
                    initial_status: 1,
                };
            } else {
                rtn.isAlert = true;
                rtn.msg = 'エラーが発生しました';
                return rtn;
            }

            const header = await getHeaderToken();

            if ( requestType === 'put' ) { 
                await axios
                .put(ep, body, header)
                .then((res) => {
                    logger.info(res);
                    if ( res.status === 204 ) {
                        rtn.status = res.status;
                        return rtn
                    } else {
                        rtn.status = res.status;
                        return rtn
                    }
                })
                .catch((e) => {
                    if ( e.response.data ) {
                        rtn.isAlert = true;
                        if ( e.response.data.wrongFormatParameters === true ) {
                            rtn.msg = '入力値に問題があり、登録できませんでした';
                        } else if ( e.response.data.asApiFailed === true ) {
                            rtn.msg = '内部でエラーが発生しました';
                        } else {
                            rtn.msg = 'エラーが発生しました';
                        }
                    } else {
                        logger.error(e);
                    }
                    return rtn
                });

            } else if ( requestType === 'post' ) {
                await axios
                .post(ep, body, header)
                .then((res) => {
                    logger.info(res);
                    if ( res.status === 204 ) {
                        rtn.status = res.status;
                        return rtn;
                    } else {
                        rtn.status = res.status;
                        return rtn;
                    }
                })
                .catch((e) => {
                    if ( e.response.data ) {
                        rtn.isAlert = true;
                        if ( e.response.data.wrongFormatParameters === true ) {
                            rtn.msg = '入力値に問題があり、登録できませんでした';
                        } else if ( e.response.data.asApiFailed === true ) {
                            rtn.msg = '内部でエラーが発生しました';
                        } else {
                            rtn.msg = 'エラーが発生しました';
                        }
                    } else {
                        logger.error(e);
                    }
                    return rtn
                });

            } else {
                rtn.isAlert = true;
                rtn.msg = 'エラーが発生しました';
                return rtn;                
            }
            return rtn   
        };

        const submitHandler = async ( requestType ) => {

            // Input validator
            if ( requestType !== 'post' && requestType !== 'put' ) { 
                logger.info(`requestType is not valid. Received ${ requestType }`);
                alert(alertMsg); 
                return; 
            }

            if ( requestType === 'put' ) {
                logger.info('PUT request will be sent')
            } else if ( requestType === 'post' ) {
                logger.info('POST request will be sent')
            }

            // Sending PUT or POST request depending on type
            const out = await sendRequest( requestType );

            // Validating response
            if ( out.isAlert === true ) { alert( out.msg ); }
            if ( out.status === 204 ) {
                logger.info('Request was success!');
                setState(statusToChange);
            } else {
                logger.info(`Request did not return 204 but ${out.status}`);
            }
            return;
        };



        // Confirm and PUT request
        let message = '以下の内容で変更されますが、よろしいですか？\r\n\r\n';
        message = message + `${ state } ----> ${ statusToChange }`;
        let confirm = window.confirm( message );
        if ( confirm === true ) { 

            if ( state === 0 || state === 1 ) { submitHandler( 'put' ); }
            else if ( state === null ) { submitHandler( 'post' ); }
            else { alert(alertMsg); return; }
        }

    } else {
        logger.info(`State passed has to be 0, 1 or null. But received ${state}`);
        alert(alertMsg);
        return;
    }
}

export default putOptionPermissionSwitch