import { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, Tab, Tabs, ListGroup, Row, Col } from 'react-bootstrap';
import axios from 'axios';

import useGetter from 'hooks/use-getter';
import useInput from 'hooks/use-input';
import getHeaderToken from 'functions/getHeaderToken';
import putOptionPermissionSwitch from 'functions/putOptionPermissionSwitch';
import checkPermissionStatus from "functions/checkPermissionStatus";

import CustomModal from 'components/UI/Modal';
import List from 'components/UI/List';
import createData from 'functions/createOfficeListData';
import createPermissionData from 'functions/createPermissionListData';

import findOfficeNameFromOfficeNumber from 'functions/officeCollaboration/findOfficeNameFromOfficeNumber'
import postOfficeCollaboration from 'functions/officeCollaboration/postOfficeCollaboration'
import deleteOfficeCollaboration from 'functions/officeCollaboration/deleteOfficeCollaboration'

import{ Logger } from 'aws-amplify';
const logger = new Logger('Office-log');


const Office = () => {

    // Endpoints
    const url = process.env.REACT_APP_OFFICES;
    const urlPlans = process.env.REACT_APP_PLANS;
    const urlPermission = process.env.REACT_APP_OFFICE_PERMISSION;
    const urlOfficeCollaboration = process.env.REACT_APP_OFFICE_COLLABOLATION;
    const urlOfficeNames = process.env.REACT_APP_OFFICE_NAMES

    const params = useParams();
    const endpoint = url + `/${params.office_no}`;
    const ep_permission = urlPermission + `/${params.office_no}`;


    // Get office data
    let res = { 
        admin_name: '',
        admin_email: '',
    };
    res = useGetter(endpoint, res);

    // Get plan data
    let resPlans = { plans: [] };
    resPlans = useGetter(urlPlans, resPlans);

    // Get office permission data
    let resPerm = { data: [] };
    resPerm = useGetter(ep_permission, resPerm);

    // Get office collaboration data
    let resOfficeCollaboration = {collaborated_offices: []};
    const getOfficeCollaborationParams = {src_office_number: params.office_no};
    resOfficeCollaboration = useGetter(urlOfficeCollaboration, resOfficeCollaboration, getOfficeCollaborationParams);
    // Office collaboration state
    const [collaboratedOffices, setCollaboratedOffices] = useState([]);

    // Get office names data
    let resOfficeNames = {office_names: {}};
    resOfficeNames = useGetter(urlOfficeNames, resOfficeNames);

    const selections = resPlans.plans.map( (obj) => <option key={obj.id}>{obj.name}</option> );
    const currentSelection = resPlans.plans.filter(obj => {return obj.id === res.plan_id});
    let currentPlan = "";
    if ( currentSelection[0] ) { currentPlan = currentSelection[0].name }

    const falseName = '連携しない';
    const trueName = '連携する';
    const mfpArray = [{ id: false, name: falseName }, { id: true, name: trueName }];
    const mfpSelections = mfpArray.map( (obj) => <option key={obj.id}>{obj.name}</option> );
    let currentMfpSelection = falseName;
    if ( res.mfp_enabled === true ) { currentMfpSelection = trueName };

    let currentPaymentInterval = null;
    if ( res.payment_interval_month === 1 ) { currentPaymentInterval = "月額" }
    else if ( res.payment_interval_month === 12 ) { currentPaymentInterval = "年額" }

    const { 
        value: enteredName,
        setValue: setEnteredName,
        isValid: isValidName,
        changeHandler: nameChangeHandler 
        } = useInput(value => 
            String(value.trim()) !== ''
            && String(value.trim()).length <= 128
            );
    const { 
        value: enteredEmail,
        isValid: isValidEmail,
        setValue: setEnteredEmail,
        changeHandler: emailChangeHandler 
        } = useInput(value =>
            /^([a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]{1,64})+$/.test(value)
            );
    const { 
        value: enteredMaxUserNum,
        isValid: isValidMaxUserNum,
        setValue: setEnteredMaxUserNum,
        changeHandler: maxUserNumChangeHandler 
        } = useInput(value =>
            value !== ''
            && value > 0
            && value <= 100
            && /^[0-9]+$/.test(value)
            );
    const { 
        value: enteredMaxStorage,
        isValid: isValidMaxStorage,
        setValue: setEnteredMaxStorage,
        changeHandler: maxStorageChangeHandler 
        } = useInput(value =>
            value !== ''
            && value > 0
            && value <= 100
            && /^[0-9]+$/.test(value)
            );
    const { 
        value: enteredPlan,
        isValid: isValidPlan,
        setValue: setEnteredPlan,
        changeHandler: planChangeHandler 
        } = useInput(value =>
            String(value.trim()) !== ''
            );
    const { 
        value: enteredStartDate,
        isValid: isValidStartDate,
        setValue: setEnteredStartDate,
        changeHandler: startDateChangeHandler 
        } = useInput(value =>
            String(value.trim()) !== '__placeholderToAvoidError'
            );
    /*
    const { 
        value: enteredEndDate,
        isValid: isValidEndDate,
        setValue: setEnteredEndDate,
        changeHandler: endDateChangeHandler 
        } = useInput(value => 
            String(value.trim()) !== '__placeholderToAvoidError'  
            );
    */
    const { 
        value: enteredCustomerId,
        setValue: setEnteredCustomerId,
        isValid: isValidCustomerId,
        changeHandler: customerIdChangeHandler 
        } = useInput(value => 
            /^([0-9]{10})+$/.test(value)
            );
    const { 
        value: enteredMfpStatus,
        setValue: setEnteredMfpStatus,
        isValid: isValidMfpStatus,
        changeHandler: mfpStatusChangeHandler 
        } = useInput(value => 
            String(value.trim()) !== ''
            );
    const { 
        value: enteredPaymentIntervalMonth,
        setValue: setEnteredPaymentIntervalMonth,
        isValid: isValidPaymentIntervalMonth,
        changeHandler: paymentIntervalMonthChangeHandler 
        } = useInput( value => 
            String(value.trim()) !== ''
        );
    const { 
        value: enteredCollaborateOfficeNumber,
        setValue: setEnteredCollaborateOfficeNumber,
        isValid: isValidCollaborateOfficeNumber,
        changeHandler: collaborateOfficeNumberChangeHandler
        } = useInput( value =>
            (value !== params.office_no)
            && (value in resOfficeNames.office_names)
            && (!collaboratedOffices.includes(value))
        );


    let isReadyToSubmit = false;
    if ( isValidName 
            && isValidEmail 
            && isValidMaxUserNum 
            && isValidMaxStorage 
            && isValidPlan 
            && isValidStartDate
            //&& isValidEndDate
            && isValidCustomerId
            && isValidMfpStatus
            && isValidPaymentIntervalMonth
    ) { isReadyToSubmit = true };

    let isFetched = false;
    if ( res.service_enabled ){ isFetched = true }else{isFetched = false};

    // States
    const [ isModal, setIsModal ] = useState(false);
    const [ isShiftExternallinkPerm, setIsShiftExternallinkPerm ] = useState(''); // 1, 0 or null

    useEffect(() => {
        //setIsShiftExternallinkPerm( !checkedStatusShiftExtlink ? '' : ( checkedStatusShiftExtlink === 1 ? 1 : ( checkedStatusShiftExtlink === 0 ? 0 : null )))
        setEnteredName( !res.admin_name ? '' : res.admin_name );
        setEnteredEmail( !res.admin_email ? '' : res.admin_email );
        setEnteredMaxUserNum( !res.max_user_num ? '' : res.max_user_num );
        setEnteredMaxStorage( !res.max_strage_size ? '' : res.max_strage_size );
        setEnteredPlan( !currentPlan ? '' : currentPlan );
        setEnteredStartDate( !res.start_date ? '' : res.start_date );
        //setEnteredEndDate( !res.end_date ? '' : res.end_date );
        setEnteredCustomerId( !res.customer_id ? '' : res.customer_id );
        setEnteredMfpStatus( !currentMfpSelection ? '' : currentMfpSelection );
        setEnteredPaymentIntervalMonth( !currentPaymentInterval ? '' : currentPaymentInterval );
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setCollaboratedOffices( !resOfficeCollaboration.collaborated_offices ? [] : resOfficeCollaboration.collaborated_offices.concat())
    }, [
        isModal,
        isShiftExternallinkPerm,
        setEnteredName, 
        setEnteredEmail, 
        setEnteredMaxUserNum,
        setEnteredMaxStorage,
        setEnteredPlan,
        setEnteredStartDate,
        //setEnteredEndDate,
        setEnteredCustomerId,
        setEnteredMfpStatus,
        setEnteredPaymentIntervalMonth,
        resOfficeCollaboration
    ]);

    const fetchPermissions4UsingEffectAndState = async () => {
        const header = await getHeaderToken();
        await axios
            .get(ep_permission, header)
            .then((res) => {
                const checkedStatusShiftExtlink = checkPermissionStatus(res.data, 'shift', 'externallink');
                logger.info('checkedStatusShiftExtlink is: ', checkedStatusShiftExtlink);
                setIsShiftExternallinkPerm(checkedStatusShiftExtlink);
            })
            .catch((e) => { logger.error(e) });
    };
    fetchPermissions4UsingEffectAndState();

    const postRequest = async (url, body) => {
        const header = await getHeaderToken();
        axios
            .put(endpoint, body, header)
            .then((res) => {
                switch (res.status) {
                    case 204:
                        logger.info('Put request success. Received 204 response');
                        window.location.reload(false);
                        break
                    default:
                        logger.error('Put request failed. The status is not 204. res is: ', res)
                        break 
                }
            })
            .catch((e) => {
                logger.error(e);
            });
    };
    /*
    const validateEnteredDates = (start, end) => {
        let isValid = false;
        if ( start === '' && end === '' ){ isValid = true 
        } else if ( start !== '' && end !== '' ){
            isValid = end > start;
            if ( isValid === false ) {
                alert('契約終了日は、開始日より後の日にちを選択してください');
            }
        } else {
            alert('契約開始日、もしくは終了日を正しく入力してください');
        }
        return isValid;
    }
    */

    const paymentIntervalHandler = (paymentIntervalString) => {

        let value = null;
        switch ( paymentIntervalString ) {
            case "月額":
                value = 1
                break
            case "年額":
                value = 12
                break
            default:
                logger.error("wrong payment interval value");
                break
        }
        return value     
    }


    const submitHandler = async (event) => {
        event.preventDefault();

        let start = enteredStartDate;
        //let isDatesValid = validateEnteredDates(start, end);
        /*
        if ( isDatesValid === false ){ 
            window.location.reload(false); 
            return
        }
        */
        if ( enteredStartDate === '' ){ start = null }
        
        const selectedPlan = resPlans.plans.filter(obj => {return obj.name === enteredPlan})
        let selectedMfpStatus = false;
        if ( enteredMfpStatus === trueName ) { selectedMfpStatus = true }

        const paymentIntervalInt = paymentIntervalHandler( enteredPaymentIntervalMonth )

        const body = {
                admin_name: enteredName,
                admin_email: enteredEmail,
                max_user_num: Number(enteredMaxUserNum),
                max_strage_size: Number(enteredMaxStorage),
                plan_id: selectedPlan[0].id,
                start_date: start,
                //end_date: null,
                customer_id: enteredCustomerId,
                mfp_enabled: selectedMfpStatus,
                payment_interval_month: paymentIntervalInt,
        };

        const initialState = {
                admin_name: res.admin_name,
                admin_email: res.admin_email,
                max_user_num: res.max_user_num,
                max_strage_size: res.max_strage_size,
                plan_id: res.plan_id,
                start_date: res.start_date,
                //end_date: res.end_date,
                customer_id: res.customer_id,
                mfp_enabled: res.mfp_enabled,
                payment_interval_month: res.payment_interval_month,
        };

        const labels = {
                admin_name: '施設管理者名',
                admin_email: '管理者email',
                max_user_num: 'ユーザ数上限',
                max_strage_size: 'ストレージ上限',
                plan_id: '契約タイプ',
                start_date: '契約開始日',
                //end_date: '契約終了日',
                customer_id: '顧客コード',
                mfp_enabled: 'IWS連携',    
                payment_interval_month: '支払いタイプ',        
        }

        let diff = [];
        for await ( const [key, value] of Object.entries(initialState) ) {   
            if ( body[key] !== value ) { 
                diff.push({ label: labels[key], before: value, after: body[key] })
            };
        }

        if ( diff.length === 0 ) { 
            alert('何も変更されていません');
            window.location.reload(false);
        } else {
            let message = '以下の内容で変更されますが、よろしいですか？\r\n\r\n';
            for await ( const obj of diff ) {
                message = message + String(obj.label) + " :   " + String(obj.before) + "  -->  " + String(obj.after) + "\r\n"
            }
            let confirm = window.confirm(message);
            if ( confirm === false ) { window.location.reload(false); }
            else {
                logger.info('body is: ', body);
                postRequest(endpoint, body);   
            }
        }
    };


    const clearDatesHandler = () => {
        const message = "契約開始日と終了日を空にしますか？"
        let confirm = window.confirm(message);
        if ( confirm === true ) {
            const body = {
                start_date: null,
                end_date: null,
            };
            postRequest(endpoint, body);
        }
    };

    const planLockHandler = () => {

        if ( res.service_code !== '510' ) {
            return (
                <Fragment>
                    <Form.Label>契約タイプ</Form.Label>
                        <Form.Control disabled defaultValue={ currentPlan }/>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <Form.Label>契約タイプ</Form.Label>
                {isValidPlan ? " ( OK! )" : ` ( 現在のプランは${currentPlan}です。変更する契約タイプを選択してください )`}
                <Form.Control as="select" onChange={planChangeHandler} data-testid="modal-input">
                    <option>{ currentPlan }</option>
                    { selections }
                </Form.Control>
            </Fragment>
        )

    };

    const deleteOfficeCollaborationHandler = (collaboratedOfficeNumber) => {
        let confirm_status = window.confirm("連携施設を削除しますか？\n\n施設番号：" + collaboratedOfficeNumber);
        if ( confirm_status ) {
            deleteOfficeCollaboration(urlOfficeCollaboration, params.office_no, collaboratedOfficeNumber, collaboratedOffices, setCollaboratedOffices);
        }
    }

    const onFocusFunc = (e) => {
        e.currentTarget.type = "date";
    };

    let modalBody = <p>Loading</p>
    if ( isFetched === true ) {
        modalBody = (
            <Form onSubmit={submitHandler}>
                <Form.Group>

                    <Form.Label>施設管理者名</Form.Label>
                        {isValidName ? " ( OK! )" : " ( 8文字以下で入力してください )"}
                        <Form.Control defaultValue={res.admin_name} onChange={nameChangeHandler} data-testid="modal-input"/>
                    <Form.Label>管理者email</Form.Label>
                        {isValidEmail ? " ( OK! )" : " ( 有効なEmailアドレスを入力してください )"}
                        <Form.Control type="email" defaultValue={res.admin_email} onChange={emailChangeHandler} data-testid="modal-input"/>
                    <Form.Label>ユーザ数上限</Form.Label>
                        {isValidMaxUserNum ? " ( OK! )" : " ( 100以下の有効な整数を入力してください )"}
                        <Form.Control type="number" defaultValue={res.max_user_num} onChange={maxUserNumChangeHandler} data-testid="modal-input"/>  
                    <Form.Label>ストレージ上限</Form.Label>
                        {isValidMaxStorage ? " ( OK! )" : " ( 100以下の有効な整数を入力してください )"}
                        <Form.Control type="number" defaultValue={res.max_strage_size} onChange={maxStorageChangeHandler} data-testid="modal-input"/>
                    <Form.Label>サービス状況</Form.Label>
                        <Form.Control disabled defaultValue="稼働中" data-testid="modal-input"/>
                    {/*
                    <Form.Label>契約タイプ</Form.Label>
                        {isValidPlan ? " ( OK! )" : ` ( 現在のプランは${currentPlan}です。変更する契約タイプを選択してください )`}
                        <Form.Control as="select" onChange={planChangeHandler} data-testid="modal-input">
                            <option>{ currentPlan }</option>
                            { selections }
                        </Form.Control>
                    */}
                    { planLockHandler() }
                    <Form.Label>契約開始日</Form.Label>
                        {isValidStartDate ? " ( OK! )" : ` ( 変更する日にちを選択してください )`}
                        <Form.Control type="text" onFocus={ onFocusFunc } defaultValue={res.start_date} onChange={startDateChangeHandler} data-testid="modal-input"/>
                    {/*
                    <Form.Label>契約終了日</Form.Label>
                        {isValidEndDate ? " ( OK! )" : ` ( 変更する開始日より後の日にちを選択してください )`}
                        <Form.Control type="text" onFocus={ onFocusFunc } placeholder={res.end_date} onChange={endDateChangeHandler} data-testid="modal-input"/>
                    */}
                    <Form.Label>支払いタイプ</Form.Label>
                        {isValidPaymentIntervalMonth ? " ( OK! )" : ` ( 契約タイプを選択してください )`}
                        <Form.Control as="select" onChange={paymentIntervalMonthChangeHandler} data-testid="modal-input">
                            <option>{ currentPaymentInterval }</option>
                            <option>月額</option>
                            <option>年額</option>
                        </Form.Control>
                    <Form.Label>顧客コード</Form.Label>
                        {isValidCustomerId ? " ( OK! )" : " ( 10桁の整数を入力してください )"}
                        <Form.Control type="number" defaultValue={res.customer_id} onChange={customerIdChangeHandler} data-testid="modal-input"/>
                    <Form.Label>IWS連携</Form.Label>
                        {isValidMfpStatus ? " ( OK! )" : " ( 選択してください )"}
                        <Form.Control as="select" onChange={mfpStatusChangeHandler} data-testid="modal-input">
                            <option>{ currentMfpSelection }</option>
                            { mfpSelections } 
                        </Form.Control>                     
                </Form.Group>
                <Button 
                    type="submit" 
                    disabled={!isReadyToSubmit}
                    data-testid='submit-button'>この情報で変更</Button>
            </Form>
        );
    }

    // Create data for List component
    let listData = {};
    let listPermissionData = [{}];
    if ( res && resPlans ){ listData = createData(res, resPlans); }
    listPermissionData = createPermissionData(resPerm);

    return (
        <Fragment>
            <Tabs 
                defaultActiveKey="info">

                <Tab
                    eventKey="info"
                    title="施設情報">
                        <br/>                    
                        <List data={ listData.dataName }/>
                        <br/>
                        <h2>基本情報</h2>
                        <List data={ listData.dataChangeable }/>
                        <Button 
                            onClick={() => setIsModal(true)}
                            data-testid='open-modal-button'
                            disabled={!isFetched}>情報を更新する</Button>
                        <br/>
                        <br/>
                        <Button
                            onClick={() => clearDatesHandler()}>契約開始日を空にする</Button>
                        <br/>
                        <br/>
                        <h2>詳細</h2>
                        <List data={ listData.dataFixed }/>

                        <CustomModal 
                            show={ isModal }
                            setShow={ () => {setIsModal()} }
                            header={ "施設情報の変更" }
                            body={ modalBody }/>
                </Tab>
                <Tab
                    eventKey="permission"
                    title="権限">
                        <br/>
                        <h2>シフト</h2>
                        <List data={ listPermissionData.shift }/>
                        <ListGroup horizontal>
                            <ListGroup.Item
                                variant="primary">
                                    勤怠ソフト管理
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Button
                                    onClick={ () => putOptionPermissionSwitch(resPerm, 'shift', 'externallink', isShiftExternallinkPerm, setIsShiftExternallinkPerm) }>
                                        { isShiftExternallinkPerm === 1 ? "有効"  : "無効" }
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                </Tab>
                <Tab
                    eventKey="office_collaboration"
                    title="施設間連携"
                    >
                        <h2 className="mt-4 mb-4">連携施設</h2>
                        {collaboratedOffices.map((collaboratedOfficeNumber) =>
                            <Form key={collaboratedOfficeNumber}>
                                <Row className="mb-2">
                                    <Col xs={2}>
                                        <Form.Control
                                            type="text"
                                            defaultValue={ collaboratedOfficeNumber }
                                            disabled
                                            readOnly
                                        />
                                    </Col>
                                    <Col xs={3}>
                                        施設名：{findOfficeNameFromOfficeNumber(collaboratedOfficeNumber, resOfficeNames.office_names)}
                                    </Col>
                                    <Col xs={1}>
                                        <Button
                                            variant="danger"
                                            onClick={() => deleteOfficeCollaborationHandler(collaboratedOfficeNumber)}>削除
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                        <Form>
                            <Row>
                                <Col xs={2}>
                                    <Form.Control
                                        value={enteredCollaborateOfficeNumber}
                                        onChange={collaborateOfficeNumberChangeHandler}
                                        placeholder="0123456789-510"
                                    />
                                </Col>
                                <Col xs={3}>
                                    施設名：{findOfficeNameFromOfficeNumber(enteredCollaborateOfficeNumber, resOfficeNames.office_names, params.office_no, collaboratedOffices)}
                                </Col>
                                <Col xs={1}>
                                    <Button
                                        disabled={!isValidCollaborateOfficeNumber}
                                        onClick={() => 
                                            postOfficeCollaboration(
                                                urlOfficeCollaboration, params.office_no, enteredCollaborateOfficeNumber,
                                                setEnteredCollaborateOfficeNumber, collaboratedOffices, setCollaboratedOffices
                                            )}>追加
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                </Tab>

            </Tabs>
        </Fragment>
    )
};

export default Office;